import * as Turbo from "@hotwired/turbo";
import { Application, Controller } from "@hotwired/stimulus";

Turbo.session.drive = false;
window.Turbo = Turbo;
window.Stimulus = Application.start();
// window.TomSelect = TomSelect;


class HelloController extends Controller {
  static classes = ["display"];
  static targets = ["menu", "menuMobile"];
  showMenu() {
    this.menuTarget.classList.toggle("hidden");
  }
}
Stimulus.register("hello", HelloController);




class FormActionSelectController extends Controller {
  static classes = ["display"];
  static targets = ["items", "item"];
  update() {
    const checkedItems = this.itemTargets.filter(item => item.checked);
    this.itemsTarget.value = checkedItems.map(item => item.value);
  }
  showModal() {
    form_action_select_modal.showModal();
  }
}
Stimulus.register("form-action-select", FormActionSelectController);
